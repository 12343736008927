import { createPinia } from 'pinia';
import { store } from 'quasar/wrappers';

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

// @ts-ignore
// function resetStore({ store }) {
//     const initialState = cloneDeep(store.$state);
//     store.$reset = () => store.$patch(cloneDeep(initialState));
// }

export default store((/* { ssrContext } */) => {
    const pinia = createPinia();
    // pinia.use(resetStore);

    // You can add Pinia plugins here
    // pinia.use(SomePiniaPlugin)

    return pinia;
});
