<template>
    <ErrorBoundary>
        <router-view />
    </ErrorBoundary>
</template>
<script lang="ts" setup>
import ErrorBoundary from 'components/error/ErrorBoundary.vue';
import { useQuasar } from 'quasar';

const $q = useQuasar();

$q.loadingBar.setDefaults({
    color: 'secondary',
    size: '3px',
    position: 'top',
    hijackFilter(url) {
        return !url.includes('predicted');
    },
});
</script>
